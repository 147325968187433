
import './intro.scss';

import ds from '../../../img/logo.png'
import grafimg from '../../../img/elms.png' 

import {useApplicationPage} from '../../../hooks/useApplicationPage.js'

const Intro  = () => {
    const {scrollToBlock} = useApplicationPage()
    const clazz = '.content--named-block'
    return(
        <div className='intro'>
            <div className='container'>
                <div className='intro__inner'>
                    <div className='intro__info'>
                        <h1 className='intro__title'>V-Bucks Fortnite Shop</h1>
                        <h2>Улучшите свои возможности в игре Fortnite благодаря нашему сервису</h2>
                        <span 
                        className='intro__btn' 
                        onClick={(e) => scrollToBlock(e, clazz)}>Купить В-Баксы</span>
                    </div>
                    <div className='intro__block'>
                        <img alt="Fortnite Vbucks Shop" className='intro__img-emodji' src={grafimg}/>
                        <img alt="Магазинов Вбаксов Фортнайт" className='intro__img' src={ds}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intro;