import { useState, useEffect } from 'react'; 
import './faq.scss';


const Faq = () => {
    const faq = [
        {title: 'Куда приходит товар?', description: 'Купленный товар приходит на почту, которую вы указываете при оформлении покупки.', id: 1},
        {title: 'Как активировать купленный ключ?', description: <> Инструкция по активации (использованию) продукта:<br/> <b>1. </b> Активировать ключ нужно по ссылке: <a href="https://www.fortnite.com/redeem?lang=ru" target="_blank" rel="noreferrer">https://www.fortnite.com/redeem?lang=ru</a><br/><b>2. </b>Авторизуйтесь, чтобы получить доступ к вашему аккаунту или создайте новый.
        <br/><b>3. </b>Введите ключ, полученный после оплаты, в соответствующее поле.
        <br/><b>4. </b>Убедитесь, что при заполнении Ваши данные верны и подтвердите нажав <b>Активировать</b>.
        <br/><b>5. </b>Поздравляем! Вы будете уведомлены о том, что на Вашу учетную запись был добавлен набор Fortnite.</>, id: 2},
        {title: 'Что делать если ключ невалидный?', description: <>Если полученный ключ не удалось активировать, отпишите нам в <a href="https://t.me/vbsuprt_bot" target='_blank' rel="noreferrer">Telegram</a>, мы всегда на связи и готовы будем вам помочь.</>, id: 3},
        {title: 'У вас все честно?', description: <>Есть одна поговорка «Довольный клиент всегда возвращается», и именно по этому мы заинтересованы в том чтобы после предосталенных нами услуг у вас не осталось никаких вопросов. </>, id: 4},
        {title: 'Какие платформы подходят?', description: <>Активировать ключ можно на игровых платформах ПК, XBOX, NINTENDO. Если у вас возникнут проблемы с активацией пишите в <a href="https://t.me/vbsuprt_bot" target='_blank' rel="noreferrer">Поддержку</a></>, id: 5},
    ]
    
    const {0: activeTab, 1: setActiveTab} = useState(null);


    function renderItems(data){
        const items = data.map((item, i) => {
            return <View key={i} item={item} activeTab={activeTab} setActiveTab={setActiveTab}/>
        })
        return items;
    }
    const content = renderItems(faq);
    return(
        <div className="block-faq"> 
            {content} 
        </div>
    )
}


const View = (props) => {
    const {title, description, id } = props.item;
    const {setActiveTab, activeTab } = props
    const {0: req, 1: setReq } = useState(false);
    const selectedReq = () => {
        if (!req && id !== activeTab){
            setReq(true);
            setActiveTab(id);
        }else{
            setReq(false);
            setActiveTab(null);
        }
    }
    useEffect(() => {
        if (activeTab !== id && req) {
          setReq(false);
        }
      }, [activeTab, id, req, setReq]);
    const clazzReq = req ? 'block-faq__request active' : 'block-faq__request';
    const clazzQue = req ? 'block-faq__quest active' : 'block-faq__quest';
    return(
        <div className="block-faq_inner">
            <div className={clazzQue} onClick={selectedReq} >
                {title}
                {activeTab === id && (
                    <p className={clazzReq}>{description}</p>
                )}
            </div>
        </div>
    )
}

export default Faq;