import { useState } from 'react';


function useApplicationPage () {
    const scrollToBlock = (e, clazz) => {
        e.preventDefault();
        const container = document.querySelectorAll(clazz);
        container[0].scrollIntoView({behavior: "smooth", block: "start"});
    }

    const {0: modal, 1: setModal} = useState(false);

    const selectedModalStatus = (e) => {
        e.preventDefault();
        setModal(!modal)

    }

    return {
        scrollToBlock,
        modal,
        setModal,
        selectedModalStatus
    }
}

export { useApplicationPage };