import { useApplicationPage } from '../../../hooks/useApplicationPage.js';

import './footer.scss';


const Footer = () => {
    const {scrollToBlock} = useApplicationPage()
    const clazz = '.pr-bg'
    return(
    <footer className="footer">
        <div className="container">
            <div className="footer__inner">
                <div className="footer__title">
                    <a href="https://v-bux.fun/"><span>VBUCKS</span>SHOP</a>
                </div>
                <nav className="footer__nav">
                    <a href="https://t.me/vbsuprt_bot" rel="nofollow noreferrer" target="_blank">Поддержка</a>
                    <a href="https://vk.com/topic-227650731_53548264" rel="nofollow noreferrer" target="_blank">Отзывы</a>
                    <a href="https://vk.com/club227650731" rel="nofollow noreferrer" target="_blank">Сообщество</a>
                    <a href="/" onClick={e => scrollToBlock(e,clazz)}>Гарантии</a>
                </nav>
            </div>
            <h5 className="footer__description">Благодаря нашему сервису вы можете улучшить свои возможности в игре Fortnite. Купите В-Баксы прямо сейчас по самым доступным ценам.</h5>
            <div className="footer__copyrite">@ 2024 <a href="https://v-bux.fun/">V-BUCKS</a></div>
        </div>
    </footer>
    )
}

export default Footer;