import React from 'react';
import { hydrate, render } from "react-dom";
import './index.css';
import App from './components/app/app.js';

const rootElement = document.getElementById("content"); 
if (rootElement.hasChildNodes()) { 
  hydrate(<App/>, rootElement); 
} else { 
  render(<App/>, rootElement);
}