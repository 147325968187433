import { useState } from 'react';

import spinner from '../../../../src/img/722.gif';
import './modal.scss';

const ModalBuy = (props) => {
    const { 0: counter, 1: setCounter } = useState(1);
    const {0: login, 1: setLogin } = useState('');
    const { 0: promo, 1: setPromo } = useState('');
    const {0: redirectOnPay, 1: setStatus} = useState(false)
    const {title, prise, description, type, img, availability, id} = props.item;

    const counterPlus = () => {
        if(counter < availability ){
            setCounter(counter + 1);
        }
    }

    const counterMinus = () => {
        if(counter !== 1 ){
            setCounter(counter - 1);
        }
    }

    const clazz = props.modal ? 'modal active' : 'modal';
    const clazzHand = redirectOnPay ? 'modal__handler activeHandler' : 'modal__handler';
    const prs = prise * counter;
    const prom = promo.toUpperCase() === 'VBX10';
    
    const prsSub = prom ? Math.floor(prs * 0.90) : prs;

    const postData = async(url,data) => {
        const res = await fetch(url, {
            method: 'POST',
            body: data
        });
    
        return await res.text();
    };

    const submitForm = (e) => {
        e.preventDefault();

        const data = new FormData();

        data.append('price', prsSub);
        data.append('login', login);

        setStatus(true);
        postData('../../services/pay.php', data)
        .then(data => window.location.href = data)
    }

    return(
    <div key={id} onClick={e => props.selectedModalStatus(e)} className={clazz}>
        <div className="fadeModal modal__content modalanimation">
            <form 
            onSubmit={e => submitForm(e)}
            className="modal__form modalanimation"
            onClick ={e => e.stopPropagation(e)}>
                <div 
                onClick={e => props.selectedModalStatus(e)}
                className="modal__close">&times;</div>
                <div className="modal__title"><svg viewBox="0 0 26 26" fill="none" width="12" xmlns="http://www.w3.org/2000/svg"> <path d="M7.8 20.8C6.37 20.8 5.2 21.97 5.2 23.4C5.2 24.83 6.37 26 7.8 26C9.23 26 10.4 24.83 10.4 23.4C10.4 21.97 9.23 20.8 7.8 20.8ZM0 0V2.6H2.6L7.28 12.48L5.46 15.6C5.33 15.99 5.2 16.51 5.2 16.9C5.2 18.33 6.37 19.5 7.8 19.5H23.4V16.9H8.32C8.19 16.9 8.06 16.77 8.06 16.64V16.5099L9.23 14.2999H18.85C19.89 14.2999 20.67 13.7799 21.06 12.9999L25.74 4.55C26 4.29 26 4.16 26 3.9C26 3.12 25.48 2.6 24.7 2.6H5.46L4.29 0H0ZM20.8 20.8C19.37 20.8 18.2 21.97 18.2 23.4C18.2 24.83 19.37 26 20.8 26C22.23 26 23.4 24.83 23.4 23.4C23.4 21.97 22.23 20.8 20.8 20.8Z" fill="#fff"></path></svg>Покупка</div>
                <div className="modal__content-info">
                    <div className="modal__block-product">
                        <div className='modal__block-inner'>
                            <picture>
                                <img className="modal__block-product-img" src={img} alt={title}/>
                            </picture>
                            <div className="modal__description">
                                <div className="modal__block-inner-info-first">
                                    <h1 className="modal__block-inner-info-title--modal">{title.length > 25 ? title.substr(0, 22) + '...' : title}</h1>
                                    <div className="modal__curency">
                                        <h1>{prsSub}</h1><span>₽</span>
                                    </div>
                                </div>
                                <div className="modal__block-inner-info">
                                    <h1 className="modal__block-cat">
                                        <svg width="15" height="15" viewBox="0 0 20 20" fill="none" className="mr-2" xmlns="http://www.w3.org/2000/svg">
                            <path stroke="#fff" d="M17.5709 12.1855L12.1925 17.563C12.0532 17.7024 11.8878 17.8131 11.7057 17.8885C11.5236 17.964 11.3284 18.0029 11.1313 18.0029C10.9342 18.0029 10.739 17.964 10.5569 17.8885C10.3748 17.8131 10.2094 17.7024 10.07 17.563L3.33337 10.8334V3.33337H10.8334L17.5709 10.0709C17.8501 10.3519 18.0069 10.732 18.0069 11.1282C18.0069 11.5243 17.8501 11.9044 17.5709 12.1855V12.1855Z" strokeWidth="2" strokeLinejoin="round"></path>
                            <path stroke="#fff" fillRule="evenodd" clipRule="evenodd" d="M7.70837 8.75002C7.98464 8.75002 8.24959 8.64027 8.44494 8.44492C8.64029 8.24957 8.75004 7.98462 8.75004 7.70835C8.75004 7.43209 8.64029 7.16713 8.44494 6.97178C8.24959 6.77643 7.98464 6.66669 7.70837 6.66669C7.4321 6.66669 7.16715 6.77643 6.9718 6.97178C6.77645 7.16713 6.6667 7.43209 6.6667 7.70835C6.6667 7.98462 6.77645 8.24957 6.9718 8.44492C7.16715 8.64027 7.4321 8.75002 7.70837 8.75002Z" fill="white"></path>
                                        </svg>
                                        {type}
                                    </h1>
                                    <div className="modal__counter">
                                        <span 
                                        className="modal__counter-minus"
                                        onClick={counterMinus}>-</span>
                                        <div className="modal__counter-num"><span>{counter}</span></div>
                                        <span 
                                        className="modal__counter-plus"
                                        onClick={counterPlus}>+</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal__inform'>
                            <h4>{description}</h4>
                            <br/>
                            <a href='https://t.me/vbsuprt_bot' rel="nofollow noreferrer" target="_blank">{`Задать вопрос нашей службе поддержки`}</a>
                        </div>
                        {counter === availability ? <h2 className='modal__notif'>Вы указали максимальное количество товара</h2> : null}
                    </div>
                    <div className='modal__right'>
                        <div className={clazzHand}>
                            <div className='modal__inner-handler'>
                                <img src={spinner} alt='Fortnite В-баксы Шоп'/>
                                <h5>Переход на оплату...</h5>
                            </div>
                        </div>
                        <label className='modal__label'>Email на который придет товар::</label>
                        <input onChange={e => setLogin(e.target.value)} autocomplete="off" placeholder="email@example.com" className="modal__input" required/>
                        <label className='modal__label'>Промокод (необязательный):</label>
                        <input autocomplete="off" style={{"textTransform": "uppercase"}} disabled={prom ? true : false} onChange={e => setPromo(e.target.value.toUpperCase())} placeholder="******" className={prom ? "modal__input notif" : "modal__input"} />
                        <button type="submit" className="modal__btn">Перейти к оплате</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    )
}   

export default ModalBuy;