import { useState } from 'react';

import './modal.scss';

const Push = (props) => {
    const {0: status, 1: setStatus} = useState('admins');


    const formSubmit = (e) => {
        e.preventDefault();
        setStatus('error');
        setTimeout(() => {
            setStatus('admins')
        },3500)
    }


    const clazz = props.modal ? 'modal modalpush active' : 'modal modalpush';
    return(
        <div 
        onSubmit={e => formSubmit(e)}
        className={clazz} 
        onClick={e => props.selectedModalStatus(e)}>
            <div className="fadeModal modal__contentpush modalanimation">
                <form className="modal__form modalanimation"
                onClick ={e => e.stopPropagation()}>
                    <div 
                    onClick={e => props.selectedModalStatus(e)}
                    className="modal__close">&times;</div>
                    <div className="modal__title"><svg viewBox="0 0 26 26" fill="none" width="14" xmlns="http://www.w3.org/2000/svg"> <path d="M7.8 20.8C6.37 20.8 5.2 21.97 5.2 23.4C5.2 24.83 6.37 26 7.8 26C9.23 26 10.4 24.83 10.4 23.4C10.4 21.97 9.23 20.8 7.8 20.8ZM0 0V2.6H2.6L7.28 12.48L5.46 15.6C5.33 15.99 5.2 16.51 5.2 16.9C5.2 18.33 6.37 19.5 7.8 19.5H23.4V16.9H8.32C8.19 16.9 8.06 16.77 8.06 16.64V16.5099L9.23 14.2999H18.85C19.89 14.2999 20.67 13.7799 21.06 12.9999L25.74 4.55C26 4.29 26 4.16 26 3.9C26 3.12 25.48 2.6 24.7 2.6H5.46L4.29 0H0ZM20.8 20.8C19.37 20.8 18.2 21.97 18.2 23.4C18.2 24.83 19.37 26 20.8 26C22.23 26 23.4 24.83 23.4 23.4C23.4 21.97 22.23 20.8 20.8 20.8Z" fill="#fff"></path></svg>История покупок</div>
                    {status !== 'admins' ? <h2 className='modal__notif'>Покупок по вашему ID не найдено.</h2> : null}
                    <div className='modal__push-inside'>
                        <input type="number" required="number" name="number" placeholder="Telegram User ID" className="modal__input"/>
                        <button type="submit" required="text" className="modal__btn">Проверить наличие покупок</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Push;