import Header from './header/header.js'
import Intro from './intro/intro.js';
import Product from './product/product.js'
import Garant from './garant/garant.js'
import Faq from './faq/faq.js';
import Footer from './footer/footer.js';

import './app.scss'
import '../../other/animation.scss';

const App = () => {
    return(
        <>
            <Header/>
            <main>
                <Intro/>
                <div className='container'>
                    <div className='content--named-block'>
                            <h2>Список Товаров</h2>
                            </div>
                    <Product/>
                </div>
                <div className='pr-bg'>
                    <div className='container'>
                            <div className='content--named-block-first'>
                                    <h2>Почему мы?</h2>
                            </div>
                            <Garant/>
                    </div>
                </div>
                <div className='container'>
                    <div className='content--named-block'>
                            <h2>FAQ</h2>
                    </div>
                    <Faq/>
                    <div className='content--named-block-s'>
                            <h2>Остались вопросы?</h2>
                            <h3>Вы всегда можете написать <a href="https://t.me/vbsuprt_bot" target='_blank' rel="nofollow noreferrer">нам</a></h3>
                            <a href='https://t.me/vbsuprt_bot' target='_blank' className='intro__btn' rel="nofollow noreferrer">Онлайн Поддержка</a>
                    </div>
                </div>
            </main>
            <Footer/>
        </>
    )
}

export default App;